import React, { useEffect } from "react"
import * as styles from "./slidePagination.module.css"
import InputTitle from "components/tools/InputTitle.js"
import {
  normalSildeRight,
  normalSildeLeft,
  smallSliceIcon,
  storySilideLeft,
  storySilideRight,
  fontSlideLeft,
  fontSlideRight,
} from "images"
import { customCount } from "utils"
import dayjs from "dayjs"

const SlidePagination = props => {
  const total = Math.ceil(props.total / props.postSize)

  useEffect(async () => {
    await props.getlist(props.currentPage, props.postSize)
  }, [props.currentPage])

  const onArrow = async ({ target: { id } }) => {
    if (
      (props.currentPage === 1 && id === "left") ||
      (props.currentPage === total && id === "right")
    )
      return
    if (id === "right") props.setCurrentPage(props.currentPage + 1)
    if (id === "left") props.setCurrentPage(props.currentPage - 1)
  }

  return (
    <div className={styles.con}>
      <img
        src={props.btn === "circle" ? slideBtn.circle.lf : fontSlideLeft}
        id="left"
        onClick={onArrow}
        style={{ backgroundColor: "rgba( 255, 255, 255, 0.5 )" }}
      />
      <div>
        <div className={styles.titleCon}>
          <InputTitle name={props.title} titleSize={22} />
          <div className={styles.viewAll}>
            {props.idxOff ? (
              <div />
            ) : (
              <div>
                <p>{customCount(props.currentPage)}</p>
                <p>/{customCount(total)}</p>
              </div>
            )}
            <div onClick={props.viewAll}>전체 보기</div>
            <img src={smallSliceIcon} style={{ width: "15px" }} />
          </div>
        </div>
        {props.line ? <hr /> : <div style={{ height: "20px" }} />}
        {props.children}
      </div>
      <img
        src={props.btn === "circle" ? slideBtn.circle.rg : fontSlideRight}
        id="right"
        onClick={onArrow}
      />
    </div>
  )
}

export default SlidePagination

const slideBtn = {
  circle: {
    lf: storySilideLeft,
    rg: storySilideRight,
  },
}
