import React, { useContext, useEffect, useState, useCallback } from "react"
import * as styles from "./detail.module.css"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getExhibitionListAsync } from "store/modules/exhibitionReducer.js"
import api from "apis"
import queryString from "query-string"
import modalContext from "context/modalContext"
import { storyCategory, table } from "utils/data.js"
import Layout from "components/common/layout.js"
import {
  getDateYMD,
  textEdit,
  titleEdit,
  getThumb,
  getEpisode,
  onShare,
  customCount,
  getNewTag,
} from "utils"
import peachIcons from "images/peachIcons.png"
import { smalloffFavor, smallonFavor } from "images"
import smallShare from "images/smallShare.png"
import fullView from "images/smallSliceIcon.png"
// import { onLike } from "utils"
import Like from "components/tools/Like"
import InputTitle from "components/tools/InputTitle.js"
import Slider from "components/tools/slide/Slide.js"
import Story from "components/story/story"
import SlidePagination from "components/tools/slide/slidePagination.js"
import { navigate } from "gatsby-link"
import DisplayB from "components/tools/banner/displayB"
import { getBannerAsync } from "store/modules/bannerReducer.js"
import NewTag from "components/tools/newTag"
import dompurify from "dompurify"
import dayjs from "dayjs"

const DetailPage = props => {
  const dispatch = useDispatch()
  const getExhibitionList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getExhibitionListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const getBanner = useCallback(
    position => dispatch(getBannerAsync(position)),
    [dispatch]
  )

  const { exhibitionList, total } = useSelector(
    ({ exhibitionReducer }) => exhibitionReducer,
    shallowEqual
  )

  const { exhibit_no, currentPage, pagePostSize } = queryString.parse(
    props.location.search
  )
  const [slidePage, setSlidePage] = useState(Number(currentPage))
  const [post, setPost] = useState({})
  const [associatedExhibit, setAssociatedExhibit] = useState([])
  const [dateTag, setDateTag] = useState()
  const [newTag, setNewTag] = useState(false)

  console.log(getExhibitionList)

  useEffect(async () => {
    const post = await api.getExhibitionDetail(exhibit_no)
    console.log(post)

    const associatedStory = await api.associatedStory(exhibit_no, table.get(7))
    console.log(associatedStory)
    // if (!post || !associatedStory) navigate("/news/list")

    setAssociatedExhibit(associatedStory)
    console.log(post)
    if (post)
      setPost({
        thumb_title_uuid: post.exhibit.thumb_header_uuid,
        title: post.exhibit.title,
        contents: post.exhibit.bodyText,
        intro: textEdit(post.exhibit.introText),
        time: getDateYMD(post.exhibit.publishTime, "YYYY.MM.DD"),
        Like: post.exhibit?.Like,
        like: post.exhibit?.like,
        hit: post.exhibit?.hit,
        label: post.exhibit?.label,
      })

    getDateTag(post.exhibit.publishTime)
    await getBanner("news")
  }, [exhibit_no, currentPage, pagePostSize])

  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const getDateTag = registerTime => {
    const time = getDateYMD(registerTime, "YYYY.MM.DD")
    if (time.split(".")[1][0] === "0") {
      setDateTag(time.split(".")[1][1])
    } else setDateTag(time.split(".")[1])
  }
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          width: "99vw",
          // maxWidth: "1900px",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className={styles.con}>
          <div className={styles.container}>
            <img
              src={getThumb(post?.thumb_title_uuid)}
              alt="Cinque Terre"
              height="370"
            />
            <div className={styles.detail}>
              <div className={styles.tag}>
                <div>{`${dateTag}월 스토리`}</div>
                {post?.label ? <div>{post?.label}</div> : null}
                <NewTag registerTime={post?.time} />
              </div>

              <div>{post?.title}</div>
              <div>{post?.intro}</div>
              <div style={{ height: "100px" }}>
                <div>
                  <img src={peachIcons} className={styles.userImg} />
                </div>
                <div className={styles.user}>피치서가</div>
                <div>{post?.time}</div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                justifyContent: "right",
                alignItems: "center",
                width: "760px",
                // height: "20px",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <div className={styles.veiw}>
                <div>view</div>
                <div>{post?.hit}</div>
              </div>
              <div className={styles.img}>
                <div className={styles.smallImg}>
                  {/* <img
                    src={post.Like ? smallonFavor : smalloffFavor}
                    onClick={e => {
                      onLike(e, exhibit_no, table.get(7), true)
                    }}
                  /> */}
                  <Like
                    on={smallonFavor}
                    off={smalloffFavor}
                    keyword={"exhibit"}
                    isLike={post?.Like}
                    style={{
                      img: {
                        width: "19px",
                        height: "19px",
                      },
                      count: {
                        marginLeft: "5px",
                        marginBottom: "4px",
                        fontSize: "16px",
                      },
                    }}
                    count={post?.like}
                    postNumber={exhibit_no}
                  />
                </div>
              </div>

              <div className={styles.smallImg}>
                <img
                  src={smallShare}
                  onClick={() => onShare(setmodalOpen, setModalMain)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div className={styles.body} key={dayjs.unix()}>
              {typeof window !== "undefined" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(post?.contents),
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.exhibitionTitle}>
          <InputTitle
            name={"기획전 스토리 보러 가기"}
            titleSize={22}
            style={{
              marginBottom: 30,
            }}
          />

          <div
            style={{
              display: "flex",
              fontSize: "16px",
              placeItems: "center",
              minWidth: "fit-content",
              paddingRight: "20px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/news/list")}
          >
            <div>전체보기</div>
            <img
              src={fullView}
              style={{ width: "17px", height: "17px", marginLeft: "3px" }}
            />
          </div>
        </div>

        <div className={styles.slideCon}>
          <Slider name={"associated"}>
            {associatedExhibit?.map(post => (
              <Story
                key={post.story_no}
                story_no={post.story_no}
                title={post.title}
                introText={post.introText}
                thumb_title_uuid={post.thumb_title_uuid}
                cateMain={post.cateMain}
                cateSub={post.cateSub}
                Like={post.Like}
                free={post.free}
              />
            ))}
          </Slider>
        </div>
        <div style={{ width: "100%", minWidth: "1200px" }}>
          <SlidePagination
            total={total}
            currentPage={slidePage}
            setCurrentPage={setSlidePage}
            postSize={pagePostSize}
            getlist={getExhibitionList}
            viewAll={() => {
              navigate("/news/list")
            }}
            title={"다른 기획전 보러 가기"}
            line={true}
          >
            <div className={styles.pageSlideCon}>
              {exhibitionList.map((post, i) => (
                <div
                  key={post.exhibit_no}
                  onClick={() => {
                    navigate(
                      `/news/detail?exhibit_no=${post.exhibit_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                    )
                  }}
                  style={{
                    fontWeight:
                      Number(exhibit_no) === post.exhibit_no ? "700" : "400",
                    margin: "6px 0px 6px 0px",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <div>
                      {customCount(
                        getEpisode(total, slidePage, pagePostSize, i)
                      )}
                      화
                    </div>
                    <div>{post.title}</div>
                  </div>
                  <div>{post.hit} 읽음</div>
                </div>
              ))}
            </div>
          </SlidePagination>

          <DisplayB
            style={{
              width: "780px",
              height: "147px",
              margin: "96px auto 30px auto",
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default DetailPage
