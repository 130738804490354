// extracted by mini-css-extract-plugin
export var con = "detail-module--con--1mdl6";
export var container = "detail-module--container--2nl-2";
export var body = "detail-module--body--3FtRk";
export var rightBox = "detail-module--rightBox--173dN";
export var slideCon = "detail-module--slideCon--2Kg7O";
export var slideChild = "detail-module--slideChild--3eSiQ";
export var pageSlideCon = "detail-module--pageSlideCon--2plRn";
export var veiw = "detail-module--veiw--jDcee";
export var smallImg = "detail-module--smallImg--lfIZl";
export var img = "detail-module--img--xNsyk";
export var slidePagination = "detail-module--slidePagination--tPw9H";
export var tag = "detail-module--tag--1mJCq";
export var detail = "detail-module--detail--2F69h";
export var user = "detail-module--user--2twMz";
export var userImg = "detail-module--userImg--1l2si";
export var exhibitionTitle = "detail-module--exhibitionTitle--1q4rb";