import React, { useEffect, useCallback } from "react"
import { getThumb } from "utils"
import { resetDisplay } from "store/modules/bannerReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import api from "apis"
const DisplayB = props => {
  const dispatch = useDispatch()
  const resetDisplayDis = useCallback(
    () => dispatch(resetDisplay()),
    [dispatch]
  )

  console.log(props.style)

  const { display } = useSelector(
    ({ bannerReducer }) => bannerReducer,
    shallowEqual
  )

  useEffect(() => () => resetDisplayDis(), [])

  return (
    <>
      {display?.thumb_title_uuid ? (
        <a
          onClick={async () => {
            await api.bannerHit({
              body: {
                banner_number: display?.banner_no,
              },
            })
            window.location.href = display?.link
          }}
        >
          <img
            src={getThumb(display?.thumb_title_uuid)}
            alt={display?.title}
            style={{ cursor: "pointer", ...props.style }}
          />
        </a>
      ) : null}
    </>
  )
}

export default DisplayB
